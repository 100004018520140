export function listenURLRedirectItinerary() {
    if ('URLSearchParams' in window) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const redirectItinerary = urlSearchParams.get('redirect_itinerary');

        if (redirectItinerary) {
            sessionStorage.setItem('redirect_itinerary', redirectItinerary);
        } else {
            sessionStorage.removeItem('redirect_itinerary');
        }
    }
}
