import { initInputPasswordToggle } from "../InputPasswordToggle";
import { formValidation } from "../formValidation";
import { listenURLRedirectItinerary } from "../listenURLRedirectItinerary";

document.addEventListener('DOMContentLoaded', () => {

    initInputPasswordToggle();

    formValidation();

    listenURLRedirectItinerary();

});
