export function inputPasswordToggle(button, inputTarget, force) {
    let isOn = typeof force !== 'undefined'
        ? force
        : inputTarget.type !== 'text';

    button.setAttribute('aria-checked', isOn);

    return inputTarget.type = isOn ? 'text' : 'password';
}

export function initInputPasswordToggle() {
    document.querySelectorAll('[data-input-password-toggle]').forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault();

            const inputSelector = button.getAttribute('data-input-password-toggle');
            const input = document.querySelector(inputSelector);

            if (input) {
                inputPasswordToggle(button, input);
            }
        });
    });
}
