export const TOKEN_INPUT_DIRTY = 'form-control-dirty';
export const TOKEN_INPUT_FILLED = 'form-control-filled';
export const TOKEN_INPUT_INVALID = 'form-control-invalid';

export function formValidation(formTriggerEvent = 'keyup') {
    formInputHandling({ checkValidity: true });

    const htmlFormElement = document.querySelector('form');
    const submitButtons = htmlFormElement.querySelectorAll('[type=submit]');
    const submitButton = submitButtons.length === 1
        ? submitButtons[0]
        : htmlFormElement.querySelector('[type=submit]#submitButton');

    htmlFormElement.addEventListener(formTriggerEvent, e => {
        const isFormValid = htmlFormElement.checkValidity();
        if (isFormValid && submitButton.disabled) {
            submitButton.removeAttribute('disabled');
        }
        if (!isFormValid && !submitButton.disabled) {
            submitButton.setAttribute('disabled', 'true');
        }
    });
}

export function formInputHandling({ checkValidity = false }) {
    document.querySelectorAll('.form-input,  .form-input-button').forEach(formControl => {
        let inputElem = formControl.querySelector('input');
        
        if(!inputElem) {
            inputElem = formControl.querySelector('textarea')
        }

        if (inputElem.value) {
            formControl.classList.add(TOKEN_INPUT_DIRTY);
            formControl.classList.add(TOKEN_INPUT_FILLED);
        }

        inputElem.addEventListener('change', e => {
            const input = e.target;
            formControl.classList.add(TOKEN_INPUT_DIRTY);

            if (input.value) {
                formControl.classList.add(TOKEN_INPUT_FILLED);
            }
            else {
                formControl.classList.remove(TOKEN_INPUT_FILLED);
            }

            if (checkValidity) {
                if (!input.checkValidity()) {
                    formControl.classList.add(TOKEN_INPUT_INVALID);
                } else {
                    formControl.classList.remove(TOKEN_INPUT_INVALID);
                }
            }
        });

        if (checkValidity) {
            inputElem.addEventListener('keyup', e => {
                const input = e.target;

                if (!formControl.classList.contains(TOKEN_INPUT_DIRTY)) {
                    return;
                }

                if (!input.checkValidity()) {
                    formControl.classList.add(TOKEN_INPUT_INVALID);
                } else {
                    formControl.classList.remove(TOKEN_INPUT_INVALID);
                }
            });
        }
    });
}
